<template>
  <q-dialog ref="dialog" persistent @hide="hide">
    <q-card class="dialog-content">
      <q-btn class="dialog-close" flat round dense v-close-popup>
        <img src="../../assets/icons/close.svg" alt="Закрыть окно" title="Закрыть окно">
      </q-btn>
      <div class="dialog-header">
        <img src="../../assets/icons/dialog/add-term.svg" class="dialog-header-icon" alt="">
        Запрос на добавление слова
      </div>

      <q-banner v-if="error" inline-actions class="text-white bg-red">{{ error }}</q-banner>

      <q-form @submit.prevent="onSubmit">
        <p>
          Это слово будет отправлено на рассмотрение редактору.
        </p>

        <q-input
          v-model="form.name.value"
          label="Слово"
          outlined
          required
          :error="form.name.error != null"
          :error-message="form.name.error"
          maxlength="200"
          ref="name"
        />

        <div class="dialog-actions">
          <q-btn label="Отменить" flat class="cancel-button" @click.prevent="onCancelClick" />
          <q-btn label="Создать запрос" type="submit" color="primary" :loading="processing" />
        </div>
      </q-form>

    </q-card>
  </q-dialog>
</template>

<script>
import { api as termsRequestsApi } from '../../api/termsRequests.js'

export default {
  name: 'AddTermRequestDialog',
  async mounted() {
    await this.$nextTick()
    this.$refs.name.$el.querySelector('input').focus()
  },
  props: {
    termRequest: {
      type: Object,
      required: true,
    },
  },
  emits: ['ok'],
  data() {
    return {
      processing: false,
      error: null,

      form: {
        name: {
          value: this.termRequest.name,
          error: null,
        },
      }
    }
  },
  methods: {
    show () {
      this.$refs.dialog.show()
    },
    hide () {
      this.$refs.dialog.hide()
    },
    onCancelClick () {
      this.hide()
    },
    onSubmit() {
      const termRequest = termsRequestsApi.create()
      termRequest.name = this.form.name.value

      this.processing = true
      termsRequestsApi
        .save(termRequest)
        .then(termRequest => {
          this.$emit('ok', termRequest)
          this.hide()
        })
        .catch(errors => {
          this.processing = false
          if (Array.isArray(errors)) {
            errors.forEach(error => {
              if (error.source) {
                switch (error.source) {
                  case 'title':
                    this.form.name.error = error.title
                    break
                }
              } else {
                this.error = error.title
              }
            })
          }
        })
    },
  },
}
</script>
