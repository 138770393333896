<template>
  <page-header title="Главная" />

  <div class="row">
    <q-card class="col terms-filter">
        <div class="terms-filter__header">Поиск слова</div>
        <q-input v-model="filterForm.q" type="search" label="Введите слово" outlined clearable autofocus @keyup="setQ" @clear="setQ">
          <template v-slot:prepend>
            <img src="../assets/icons/search.svg" alt="">
          </template>
        </q-input>

        <div @click="toggleAdvancedSearch" class="terms-filter__toggle" :class="{'terms-filter__toggle--opened': filterForm.showAdvanced}">
          Расширенный поиск
        </div>

        <div v-if="filterForm.showAdvanced" class="terms-filter__advanced">
          <q-select
            v-model="filterForm.category"
            label="Выберите отрасль"
            outlined
            input-debounce="0"
            :options="categoriesOptions"
            map-options
            option-value="id"
            option-label="name"
            @update:model-value="setCategory"
            clearable
          >
            <template v-slot:option="scope">
              <q-item v-bind="scope.itemProps">
                <q-item-section>
                  <q-item-label>{{ '—'.repeat(scope.opt.level) }} {{ scope.opt.name }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-select>

          <div class="terms-filter__letters">
            <div class="terms-filter__letters-cyrillic" :style="{'width': (letters.cyrillic.length * 32)+'px'}">
              <button v-for="letter in letters.cyrillic" :key="letter" :name="letter" @click="setLetter(letter)" class="letter" :class="{selected: letter === filter.letter}">
                {{ letter }}
              </button>
            </div>
            <div class="terms-filter__letters-latin" :style="{'width': (letters.cyrillic.length * 32)+'px'}">
              <button v-for="letter in letters.latin" :key="letter" :name="letter" @click="setLetter(letter)" class="letter" :class="{selected: letter === filter.letter}">
                {{ letter }}
              </button>
            </div>
          </div>
        </div>
    </q-card>

    <most-active-readers
      v-if="mostActiveReaders !== null"
      :readers="mostActiveReaders"
    />
  </div>

  <div v-if="searching">
    <div>Поиск...</div>
  </div>

  <template v-if="results !== null">
    <template v-if="total > 0">
      <q-card class="results">
        <div class="results__title">Результаты поиска ({{ total }})</div>

        <div v-for="(term, index) in results" :key="term.id" class="result">
          <span class="result__index">
            {{ index + 1 + (filter.page - 1) * 30 }}.
          </span>
          <div class="result__content">
            <router-link :to="{ name:'terms_view', params: { id: term.id }}">{{ term.name }}</router-link>

            <div class="result__teaser">{{ term.teaser }}</div>
          </div>
        </div>
      </q-card>

      <pagination :page="filter.page" :pages="pages" :to-fn="paginationFn" />

      <div v-if="canAddTermRequest" class="not-found-question">
        <span @click="showAddTermRequestDialog">Не нашли нужное слово?</span>
      </div>
    </template>
    <div v-else class="no-results-wrapper">
      <q-card class="no-results">
        <div>
          <img src="../assets/icons/no-results.svg" class="no-results__icon" alt="">
          <div class="no-results__title">Ничего не найдено</div>
          <template v-if="canAddTermRequest">
            <div class="no-results__content">
              Не нашли слово?<br />
              Вы можете отправить запрос<br />
              на добавление этого слова.
            </div>
            <q-btn color="primary" class="no-results__request" @click="showAddTermRequestDialog">Отправить запрос</q-btn>
          </template>
        </div>
      </q-card>
    </div>
  </template>
</template>

<script>
import loading from '../loading.js'
import api from '../api/api.js'
import MostActiveReaders from '../blocks/home/MostActiveReaders.vue'
import PageHeader from '../blocks/PageHeader.vue'
import AddTermRequestDialog from '../dialogs/termsRequests/AddTermRequestDialog.vue'
import Pagination from '../blocks/Pagination.vue'

const createFilter = function(query) {
  const filter = {
    page: 1,
    q: null,
    category: null,
    letter: null,
    publishedOnly: true,
    isNotEmpty() {
      return this.q || this.letter
    }
  }

  if (query) {
    if (query['page']) {
      filter.page = parseInt(query['page'])
    }
    if (query['q']) {
      filter.q = query['q']
    } else if (query['letter']) {
      filter.letter = query['letter']
    }
    if (query['category']) {
      filter.category = query['category']
    }
  }

  return filter
}

export default {
  components: {
    PageHeader,
    MostActiveReaders,
    Pagination,
  },
  setup() {
    return {
      canAddTermRequest: api.auth.getCurrentUser().role.id === 'student'
    }
  },
  async beforeRouteEnter (to, from, next) {
    loading.start()

    const categories = await api.categories.findTree()
    const categoriesOptions = []
    const processLevel = categories => {
      categories.forEach(category => {
        categoriesOptions.push(category)
        processLevel(category.children)
      })
    }
    processLevel(categories)

    const letters = await api.terms.getLetters(true)

    const filter = createFilter(to.query)

    let results = null
    let pages = 1
    let total = 0
    if (filter.isNotEmpty()) {
      results = await api.terms.findBy(filter)
      pages = results.meta.pages
      total = results.meta.total
    }

    const mostActiveReaders = await api.analytics.fetchMostActiveReaders()

    next(vm => {
      vm.letters = letters
      vm.categoriesOptions = categoriesOptions
      vm.setFilter(filter)
      vm.results = results
      vm.pages = pages
      vm.total = total

      if (filter.letter) {
        vm.filterForm.showAdvanced = true
      }

      vm.mostActiveReaders = mostActiveReaders
    })
    loading.updateTitle(to.meta.title || '')
    loading.stop()
  },
  async beforeRouteUpdate(to, from, next) {
    loading.start()
    const filter = createFilter(to.query)
    this.setFilter(filter)
    await this.doSearch()
    next()
    loading.stop()
    loading.updateTitle(to.meta.title || '')
  },
  data() {
    return {
      searching: false,
      filter: createFilter(),
      filterForm: {
        q: '',
        category: '',
        letter: '',
        showAdvanced: false,
      },
      results: null,
      letters: [],
      categoriesOptions: [],
      pages: 0,
      total: 0,
      mostActiveReaders: null,
    }
  },
  methods: {
    setFilter(filter) {
      this.filter = filter
      this.filterForm.q = filter.q
      this.filterForm.category = filter.category
      this.filterForm.letter = filter.letter
    },
    updateUrl() {
      const url = new URL(window.location)
      if (this.filter.q) {
        url.searchParams.set('q', this.filter.q)
      } else {
        url.searchParams.delete('q')
      }
      if (this.filter.letter) {
        url.searchParams.set('letter', this.filter.letter)
      } else {
        url.searchParams.delete('letter')
      }
      if (this.filter.category) {
        url.searchParams.set('category', this.filter.category)
      } else {
        url.searchParams.delete('category')
      }
      if (this.filter.page > 1) {
        url.searchParams.set('page', this.filter.page)
      } else {
        url.searchParams.delete('page')
      }
      history.replaceState({}, null, url.toString())
    },
    setQ() {
      this.filter.q = this.filterForm.q
      this.filter.letter = null
      this.filter.page = 1
      this.setFilter(this.filter)
      this.updateUrl()
      if (this.filter.q) {
        this.doSearch()
      } else {
        this.results = null
      }
    },
    setCategory() {
      this.filter.category = this.filterForm.category ? this.filterForm.category.id : null
      this.filter.page = 1
      this.setFilter(this.filter)
      this.updateUrl()
      this.doSearch()
    },
    setLetter(letter) {
      this.filter.q = null
      this.filter.letter = letter
      this.filter.page = 1
      this.setFilter(this.filter)
      this.updateUrl()
      this.doSearch()
    },
    async doSearch() {
      this.searching = true
      if (this.filter.isNotEmpty()) {
        this.results = await api.terms.findBy(this.filter)
        this.pages = this.results.meta.pages
        this.total = this.results.meta.total
      }
      else {
        this.results = null
        this.pages = 1
        this.total = 0
      }
      this.searching = false
    },
    paginationFn(page) {
      const newFilter = {}
      if (page > 1) {
        newFilter.page = page
      }
      if (this.filter.q) {
        newFilter.q = this.filter.q
      }
      if (this.filter.category) {
        newFilter.category = this.filter.category
      }
      if (this.filter.letter) {
        newFilter.letter = this.filter.letter
      }

      return { query: newFilter }
    },
    toggleAdvancedSearch() {
      if (this.filterForm.showAdvanced) {
        this.filterForm.showAdvanced = false
        this.filter.category = null
        this.filter.letter = null
        this.setFilter(this.filter)
        this.doSearch()
        this.updateUrl()
      } else {
        this.filterForm.showAdvanced = true
      }
    },
    showAddTermRequestDialog() {
      this.$root
        .createDialog({
          component: AddTermRequestDialog,
          componentProps: {
            termRequest: { name: this.filterForm.q },
          },
        })
        .onOk(termRequest => {
          this.$root.showSuccessMessage(`Слово «${termRequest.name}» отправлено на рассмотрение редактору.`)
        })
    },
  },
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.page-home {
  display: flex;
  flex-direction: column;
}

.terms-filter {
  padding: 30px;
  margin-bottom: 30px;

  &__header {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 24px;
  }

  &__toggle {
    position: relative;
    display: inline-block;
    padding-right: 20px;
    margin-top: 24px;
    font-weight: 600;
    font-size: 14px;
    color: #2C7DDC;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      right: 0;
      top: 3px;
      border-top: 1px solid #2C7DDC;
      border-right: 1px solid #2C7DDC;
      border-radius: 2px;
      transform: rotate(135deg);
    }

    &--opened {
      &::after {
        transform: rotate(-45deg);
        top: 10px;
      }
    }
  }

  &__advanced {
    margin-top: 30px;
  }

  &__letters {
    text-align: center;
    margin-top: 42px;
    width: 100%;
    overflow-y: auto;
    padding-bottom: 20px;

    @media (max-width: $breakpoint-xs-max) {
      text-align: left;
    }
  }

  &__letters-cyrillic {
    margin: 0 auto 0 auto;
  }

  &__letters-latin {
    margin: 26px auto 10px auto;
  }

  .letter {
    position: relative;
    cursor: pointer;

    width: 28px;
    height: 28px;

    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    text-transform: capitalize;
    border: none;

    margin: 0 2px;

    background: #EFEFEF;
    &.selected {
      background: #2C7DDC;
      color: #FFFFFF;

      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        left: 10px;
        top: 24px;

        background: #2C7DDC;
        border-radius: 2px;
        transform: rotate(-45deg);
      }
    }

    &:hover {
      background: #4A98F3;
      color: #FFFFFF;
    }
  }
}

.results {
  &__title {
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;

    padding: 28px 20px;
  }
}

.result {
  display: flex;
  position: relative;
  padding: 12px 0;
  border-top: 2px solid #F5F6FA;

  &__index {
    padding: 0 6px 0 20px;
    color: #2C7DDC;
    text-align: right;
  }

  &__teaser {
    position: relative;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    margin: 3px 0 0 0;
  }
}

.no-results-wrapper {
  display: flex;
  flex: 1;
}

.no-results {
  position: relative;
  width: 100%;
  max-width: 550px;
  margin: auto;
  padding: 60px 60px 60px 122px;

  &__icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: 73px;
  }

  &__title {
    font-family: Raleway, sans-serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
  }

  &__content {
    margin-top: 27px;
    color: rgba(0, 0, 0, 0.53);
  }

  &__request {
    margin-top: 28px;
  }
}

.not-found-question {
  margin-top: 23px;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #2C7DDC;
  cursor: pointer;
}
</style>
