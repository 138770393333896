<template>
  <q-card class="active-reader">
    <div class="active-reader__header">
      <div class="active-reader__title">
        Самый активный читатель
      </div>
      <div class="active-reader__select">
        <q-btn :label="currentMode.name">
          <q-menu class="active-reader__menu" anchor="bottom middle" self="top middle">
            <q-list>
              <q-item v-for="mode in modes" :key="mode.id" clickable v-close-popup @click="currentMode = mode" :class="{'active': currentMode.id === mode.id}">
                <q-item-section>{{ mode.name }}</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </div>
    </div>

    <div class="active-reader__content">
      <template v-if="readerName">
        <img src="../../assets/icons/active.svg" class="active-reader__icon" alt="">
        <span class="active-reader__name">{{ readerName }}</span>
      </template>
      <span v-else class="active-reader__no-data">{{ noDataTitle }}</span>
    </div>
  </q-card>
</template>

<script>
const modes = [
  {id: 'week', name: 'За неделю'},
  {id: 'month', name: 'За месяц'},
  {id: 'year', name: 'За год'},
]

export default {
  name: 'MostActiveReaders',
  props: {
    readers: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      modes: modes,
      currentMode: modes[0],
    }
  },
  computed: {
    readerName() {
      return this.readers[this.currentMode.id]?.name
    },
    noDataTitle() {
      const texts = {
        week: 'Нет данных за неделю.',
        month: 'Нет данных за месяц.',
        year: 'Нет данных за год.',
      }
      return texts[this.currentMode.id]
    },
  },
}
</script>

<style lang="scss">
.active-reader {
  padding: 12px;
  margin-left: 30px;
  margin-bottom: 30px;
  max-height: 195px;
  width: 310px;

  @media (max-width: 1000px) {
    margin-left: 0;
    order: -1;
    width: 100%;
  }

  &__header {
    padding: 10px 0 10px 0;
  }

  &__title {
    text-align: center;
    font-family: Raleway, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
  }

  &__select {
    text-align: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #2C7DDC;

    .q-btn .block {
      position: relative;
      padding-right: 20px;

      &::after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        right: 0;
        top: 6px;
        border-top: 1px solid #2C7DDC;
        border-right: 1px solid #2C7DDC;
        border-radius: 2px;
        transform: rotate(135deg);
      }
    }
  }

  &__menu {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;

    .q-item {
      padding-left: 36px;
      padding-right: 36px;
    }

    .active {
      font-weight: 600;
    }
  }

  &__icon {
    margin-right: 8px;
    display: inline-block;
  }

  &__name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70%;
  }

  &__content {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
  }

  &__no-data {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
