<template>
  <h1 class="login-title">Восстановление пароля</h1>

  <q-form @submit="onSubmit" class="password-reset-form">
    <q-input
      v-model="form.token.value"
      label="Код"
      outlined
      required
      :error="form.token.error != null"
      :error-message="form.token.error"
    />

    <q-input
      v-model="form.password.value"
      outlined
      :type="form.password.hidden ? 'password' : 'text'"
      label="Новый пароль"
      :error="form.password.error != null"
      :error-message="form.password.error"
      maxlength="200"
      required="true"
    >
      <template v-slot:append>
        <q-icon
          :name="form.password.hidden ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="form.password.hidden = !form.password.hidden"
        />
      </template>
    </q-input>

    <q-input
      v-model="form.passwordRepeat.value"
      outlined
      :type="form.password.hidden ? 'password' : 'text'"
      label="Повторите новый пароль"
      :error="form.passwordRepeat.error != null"
      :error-message="form.passwordRepeat.error"
      maxlength="200"
      required="true"
    >
      <template v-slot:append>
        <q-icon
          :name="form.password.hidden ? 'visibility_off' : 'visibility'"
          class="cursor-pointer"
          @click="form.password.hidden = !form.password.hidden"
        />
      </template>
    </q-input>

    <div class="password-reset-form__actions">
      <q-btn
        class="password-reset-form__login"
        type="submit"
        label="Применить"
        color="primary"
        :loading="processing"
      />
    </div>
  </q-form>
  <div class="q-mt-lg">
    <router-link :to="{name: 'login'}">Войти</router-link>
  </div>
</template>

<script>
import { api as authApi } from '../api/auth.js'

export default {
  data() {
    const url = new URL(window.location.href)
    const token = url.searchParams.get('token')
    return {
      form: {
        token: {
          value: token,
          error: null,
        },
        password: {
          value: '',
          hidden: true,
          error: null,
        },
        passwordRepeat: {
          value: '',
          error: null,
        },
      },
      processing: false,
    }
  },
  methods: {
    onSubmit() {
      let hasErrors = false

      if (this.form.password.value !== this.form.passwordRepeat.value) {
        this.form.password.error = 'Пароли не совпадают.'
        this.form.passwordRepeat.error = 'Пароли не совпадают.'
        hasErrors = true
      }

      if (!hasErrors) {
        authApi.resetPassword(this.form.token.value, this.form.password.value)
          .then(() => {
            this.$root.showSuccessMessage('Пароль изменён.')
            this.$router.push({name: 'login'})
          })
          .catch(errors => {
            if (Array.isArray(errors)) {
              errors.forEach(error => {
                switch (error.source) {
                  case 'token':
                    this.form.token.error = error.title
                    break;
                  case 'password':
                    this.form.password.error = error.title
                    break;
                }
              })
            }
          })

      }
    }
  }
}
</script>

<style lang="scss">
@import "~@/spa/styles/quasar.variables.scss";

.password-reset-form {
  width: 100%;
  max-width: 440px;
  padding: 0 30px;

  .q-field__native[required] ~ .q-field__label:after {
    content: none;
  }

  .q-field {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 20px;

    button {
      padding: 10px 15px;
      text-transform: uppercase;
      color: $primary;

      &[type="submit"] {
        background: $primary;
        color: #FFFFFF;
        text-transform: none;
      }
    }
  }
}
</style>
